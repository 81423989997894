* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.conatiner {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    display: flex;
    justify-content: right;
    background-image: linear-gradient(#fcf5f5, #eff4fd);
    padding: 20px 20px;
}

.main-div {
    width: 70vw;
    padding: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;

}


h1 {
    text-align: center;
    font-family: serif;
    text-transform: capitalize;
    font-size: 32px;
    letter-spacing: 5;
    word-spacing: 5px;
    margin-bottom: 50px;
    color: #2c3148;
}

.main-heading {
    display: flex;
    margin-top: 30px;
    padding: 20px 0 20px 20px;
    background: #f1f4ff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16)0px 1px 4px;

}

.main-heading p {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.1) 0px 4px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #fff;
    padding: 5px;
}

.main-heading h3 {
    padding: 5px 10px;
}

.answers {
    padding: 30px 20px 40px 20px;
    font-family: sans-serif;
    font-size: 15px;
    word-spacing: 2px;
    font-weight: 400;
    line-height: 1.7;
    background: #fcf5f5;
    color: rgb(40, 37, 53);
}

.nav-dropdownitem-arrow {
    color: white;
      }