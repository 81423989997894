.nav-dropdownitem-arrow {
  color: white;
}

.nav-services .nav-dropdown-conatiner {
  display: flex;
  overflow: visible;
}

.visible {
  display: flex;
}