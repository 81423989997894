@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap);
:root {
  --req-primary-color: #7047ea;
  --req-title-color: #0d0c22;
  --req-subtitle-color: #3d3d4e;
  --req-white-color: white;
  --req-grey-bg: #f7f7f9;
  --req-darkgrey-bg: #f1f0f6;
  -req-lightgreen-color: #2eca73;
  --req-font-family: "Lato", sans-serif;
  --req-section-head: 27.5px;
  --req-section-subhead: 14px;
  --req-article-head: 14px;
  --req-article-subtext: 1rem;
  --req-article-text: 10px;
  --req-article-tags: 10px;
  --req-w-article-head: 600;
  --req-w-article-text: 400;
  --req-lineheight: 1.5;
  -req-font-size-12: 12px;
}

* {

  margin: 0;
  padding: 0;
}

body {
  /* position: relative; */
  /* display: block; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d0c22;
  background-color: var(--req-title-color);
  /* background-color: white; */
  /* box-sizing: border-box; */
  font-family: "Lato", sans-serif;
  font-family: var(--req-font-family);
  /* height: 100vh; */
  /* width: 100vw;  */
}

@media only screen and (min-width: 768px) {
  body {
    max-height: 1000px;
    background-color: #2eca73;
  }
}

@media only screen and (min-width: 1024px) {
  body {
    /* height: 100vh; */
    background-color: #7047ea;
  }
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  border: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  /* color: var(--req-subtitle-color); */
}

input {
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
}

.App {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#root {
  width: -webkit-fill-available;
}
.st3 {
  fill: #dff0fc;
}
.st5 {
  fill: #69cdff;
}
.ld-spin {
  will-change: transform;
}
.ld.ld-spin {
  -webkit-animation: ld-spin 1s infinite;
  animation: ld-spin 1s infinite;
}
.st3 path {
  stroke-width: 0;
}

@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nav-dropdownitem-arrow {
  color: white;
}

.nav-services .nav-dropdown-conatiner {
  display: flex;
  overflow: visible;
}

.visible {
  display: flex;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.conatiner {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    display: flex;
    justify-content: right;
    background-image: linear-gradient(#fcf5f5, #eff4fd);
    padding: 20px 20px;
}

.main-div {
    width: 70vw;
    padding: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;

}


h1 {
    text-align: center;
    font-family: serif;
    text-transform: capitalize;
    font-size: 32px;
    letter-spacing: 5;
    word-spacing: 5px;
    margin-bottom: 50px;
    color: #2c3148;
}

.main-heading {
    display: flex;
    margin-top: 30px;
    padding: 20px 0 20px 20px;
    background: #f1f4ff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16)0px 1px 4px;

}

.main-heading p {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.1) 0px 4px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #fff;
    padding: 5px;
}

.main-heading h3 {
    padding: 5px 10px;
}

.answers {
    padding: 30px 20px 40px 20px;
    font-family: sans-serif;
    font-size: 15px;
    word-spacing: 2px;
    font-weight: 400;
    line-height: 1.7;
    background: #fcf5f5;
    color: rgb(40, 37, 53);
}

.nav-dropdownitem-arrow {
    color: white;
      }
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.conatiner {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    display: flex;
    justify-content: right;
    background-image: linear-gradient(#fcf5f5, #eff4fd);
    padding: 20px 20px;
}

.main-div {
    width: 70vw;
    padding: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;

}


h1 {
    text-align: center;
    font-family: serif;
    text-transform: capitalize;
    font-size: 32px;
    letter-spacing: 5;
    word-spacing: 5px;
    margin-bottom: 50px;
    color: #2c3148;
}

.main-heading {
    display: flex;
    margin-top: 30px;
    padding: 20px 0 20px 20px;
    background: #f1f4ff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16)0px 1px 4px;

}

.main-heading p {
    cursor: pointer;
    border-radius: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.1) 0px 4px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background: #fff;
    padding: 5px;
}

.main-heading h3 {
    padding: 5px 10px;
}

.answers {
    padding: 30px 20px 40px 20px;
    font-family: sans-serif;
    font-size: 15px;
    word-spacing: 2px;
    font-weight: 400;
    line-height: 1.7;
    background: #fcf5f5;
    color: rgb(40, 37, 53);
}

.nav-dropdownitem-arrow {
    color: white;
      }
